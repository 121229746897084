<template>
  <div>
    <div class="main-header">
      <div class="title">轮播图管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <!-- <div class="search-row">
            <div class="search-option">
              <span class="label">轮播图标题</span>
              <a-input
                placeholder="请输入轮播图标题"
                v-model="searchBannerName"
              ></a-input>
            </div>

            <div class="empty">
              <a-button type="primary" @click="searchByOptions" icon="search"
                >搜索</a-button
              >
            </div>
            <div class="empty"></div>
            <div class="empty"></div>
          </div> -->
        </div>
        <div class="actions">
          <div class="btns" style="gap: 10px">
            <a-button icon="plus-circle" @click="addBanner">新增</a-button>
            <a-button icon="minus-circle" @click="delBanners">删除</a-button>
          </div>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :scroll="{ x: 10 }"
          >
            <template slot="createTime" slot-scope="createTime">
              {{ $dayjs(createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <div slot="customTitle">
              排序号
              <a-tooltip placement="top">
                <template slot="title">
                  <span>排序号越小轮播图越靠前</span>
                </template>
                <a-icon type="question-circle"></a-icon>
              </a-tooltip>
            </div>
            <template slot="status" slot-scope="status">
              {{ status == 0 ? "启用" : "禁用" }}
            </template>

            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="changeBannerStatus(row)">{{
                  row.status == 0 ? "禁用" : "启用"
                }}</a>
                <a @click="editBanner(row)">编辑</a>
                <a @click="delBanner([row.id])">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal
      :footer="null"
      :closable="false"
      :title="addOrEditBannersTitle"
      v-model="addBannerModal"
      :width="900"
    >
      <a-form-model
        :model="form"
        :rules="rules"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 14 }"
        ref="form"
      >
        <a-form-model-item label="轮播图标题" prop="title">
          <a-input v-model="form.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="轮播图链接" prop="jumpUrl">
          <a-input v-model="form.jumpUrl"></a-input>
        </a-form-model-item>
        <a-form-model-item label="轮播图上传" prop="image">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            action="https://upload-z0.qiniup.com"
            :data="{ token: uploadToken, key: uploadKey }"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img
              v-if="form.image"
              :src="form.image"
              alt="avatar"
              style="width: 100px; width: 100px"
            />
            <div v-else>
              <a-icon :type="uploading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">请选择文件</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="轮播图排序号" prop="sort">
          <a-input-number v-model.number="form.sort" :max="9999" :min="0" style="width:100%"></a-input-number>
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: center">
        <a-button type="primary" @click="submit" :disabled="submitDisable"
          >提交保存</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  saveBanner,
  changeBannerStatus,
  delBanner,
  getBannersList,
  getSevenNiuToken,
} from "@/network/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchBannerName: "",
      submitDisable: false,
      form: {},
      addOrEditBannersTitle: "新增轮播图",
      addBannerModal: false,
      total: 0,
      selectedRowList: [],
      selectedRowKeys: [],
      tableLoading: true,
      tableData: [],
      searchOptions: {
      },
      allUserType: [
        { title: "长者", value: 1 },
        { title: "志愿者", value: 2 },
        { title: "管理员", value: 3 },
      ],
      uploadToken: "",
      uploadKey: "",
      uploadUrl: "",
      uploading: false,
    };
  },
  methods: {
    getUploadToken() {
      return new Promise((resolve, reject) => {
        getSevenNiuToken("https://m2.zyh365.com/").then((res) => {
          this.uploadToken = res.data.token;
          this.uploadKey = res.data.key;
          this.uploadUrl = res.data.url;
          resolve();
        });
      });
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.uploading = true;
        return;
      }
      if (info.file.status === "done") {
        this.$set(
          this.form,
          "image",
          this.uploadUrl + info.file.response.key
        );
        console.log(this.form);
        this.getUploadToken();
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不能大于2MB!");
      }
      return isJpgOrPng && isLt2M && this.getUploadToken();
    },
    delBanner(ids) {
      if (!ids.length) {
        return this.$message.error("请勾选要删除的轮播图！");
      }
      this.$confirm({
        title:
          ids.length > 1
            ? "确定要删除这些轮播图吗？"
            : "确定要删除该轮播图吗？",
        onOk: () => {
          return new Promise((resolve, reject) => {
            delBanner({ ids: ids }).then((res) => {
              if (res.status == 0) {
                this.getBannersList();
                this.$message.success("删除成功！");
                resolve();
              } else {
                reject();
              }
            });
          }).catch(() => {
            this.$message.error("删除失败！");
          });
        },
      });
    },
    changeBannerStatus(row) {
      var params = {
        id: row.id,
      };
      var text = "";
      if (row.status == 1) {
        params.status = 0;
        text = "启用";
      } else {
        params.status = 1;
        text = "禁用";
      }
      this.$confirm({
        title: `确定要${text}该轮播图吗？`,
        onOk: () => {
          return new Promise((resolve, reject) => {
            changeBannerStatus(params).then((res) => {
              if (res.status == 0) {
                this.getBannersList();
                this.$message.success(`${text}成功！`);
                resolve();
              } else {
                reject();
              }
            });
          }).catch(() => {
            this.$message.error(`${text}失败！`);
          });
        },
      });
    },
    async getBannersList() {
      this.selectedRowList = [];
      this.selectedRowKeys = [];
      this.tableLoading = true;
      const { data } = await getBannersList(this.searchOptions);
      this.tableData = data
      this.total = 0;
      this.tableLoading = false;
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.submitDisable = true;
          if (this.addOrEditBannersTitle == "编辑轮播图") {
            saveBanner(this.form).then((res) => {
              if (res.status == 0) {
                this.addBannerModal = false;
                this.$message.success("编辑成功！");
                this.getBannersList();
              } else {
                this.submitDisable = false;
                this.$message.error("编辑失败！");
              }
            });
          } else {
            this.form.id = null;
            saveBanner(this.form).then((res) => {
              if (res.status == 0) {
                this.addBannerModal = false;
                this.$message.success("新增成功！");
                this.getBannersList();
              } else {
                this.submitDisable = false;
                this.$message.error("新增失败！");
              }
            });
          }
        }
      });
    },
    editBanner(row) {
     if(row.image){
       console.log(1);
     }
      this.form = {
        ...row,
      };
      this.uploading = false;
      this.addOrEditBannersTitle = "编辑轮播图";
      this.addBannerModal = true;
      this.submitDisable = false;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    addBanner() {
      this.form = {};
      this.addOrEditBannersTitle = "新增轮播图";
      this.addBannerModal = true;
      this.uploading = false;
      this.submitDisable = false;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    delBanners() {
      const ids = this.selectedRowList;
      this.delBanner(ids);
    },
 
    searchByOptions() {
      this.searchOptions.title = this.searchBannerName;
      this.getBannersList();
    },
    onSelectChange(e) {
      this.selectedRowKeys = e;
      this.selectedRowList = [];
      this.selectedRowKeys.forEach((item) => {
        this.selectedRowList.push(this.tableData[item].id);
      });
    },
  },

  created() {
    this.getBannersList();
  },
  computed: {
    rules() {
      return {
        image: [
          {
            required: true,
            message: "请上传轮播图片",
            trigger: ["chnage"],
          },
        ],
        title: [
          {
            required: true,
            message: "请输入轮播图标题",
            trigger: "blur",
          },
        ],
        jumpUrl: [{}],
        sort: [
          {
            required: true,
            message: "请输入轮播图排序号",
            trigger: "blur",
          },
        ],
      };
    },
    tableColumns() {
      return [
        {
          dataIndex: "title",
          title: "轮播图标题",
          align: "center",
          width: 200,
        },
        {
          dataIndex: "status",
          title: "状态",
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          dataIndex: "sort",
          align: "center",
          slots: { title: "customTitle" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 300,
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
</style>